<template>
  <div>
    <!-- 平台对账 -->
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--商户订单时间-->
          <div class="conditions">
            <div>商户订单时间：</div>
            <el-date-picker
              v-model="advancedForm.times"
              :editable="false"
              style="width:320px;margin-right:10px"
              value-format="timestamp"
              type="daterange"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="false"
            ></el-date-picker>
            <!-- :picker-options="{disabledDate: MixinDisabledDate}" -->
            <!-- 提现状态选择 -->
            <div>支付类型:</div>
            <el-select
              class="ipt-default"
              style="width:150px;margin-left:10px"
              v-model="advancedForm.pay_type"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.label"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="conditions">
            <el-input class="ipt-default" style="width: 200px" placeholder="请输入商品单据号进行搜索" clearable></el-input>
            <el-button class="btn-default" @click="searchEvent()">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="heepay" label="汇付宝单据号" />
        <el-table-column prop="agentpay" label="商户单据号" />
        <el-table-column prop="order_time" label="商户订单时间">
          <template
            slot-scope="scope"
          >{{ scope.row.order_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="processing_time" label="汇付宝处理时间">
          <template
            slot-scope="scope"
          >{{ scope.row.processing_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="pay_money" label="支付金额">
          <template slot-scope="scope">{{ scope.row.pay_money | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column prop="pay_type" label="支付类型"/>
        <el-table-column prop="settlement_commission" label="结算手续费">
          <template slot-scope="scope">{{ scope.row.settlement_commission | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column prop="over_time" label="结算时间">
          <template slot-scope="scope">{{ scope.row.over_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Account from "@/api/account";

export default {
  name: "platformBill",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 62:认证支付,20:网银支付,18:快捷支付,0:所有支付 */
      options: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "认证支付",
          value: 62,
        },
        {
          label: "网银支付",
          value: 20,
        },
        {
          label: "快捷支付",
          value: 18,
        },
        {
          label: "所有支付",
          value: 0,
        },
      ],
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },
      /** 列表数据 */
      tableData: "",
      /** 高级搜索数据 */
      advancedForm: {
        times: "", // 时间
        pay_type: "", // 分类
      },
    };
  },
  watch: {
    // "addMemberForm.region"(next, prev) {
    //   if (next && next.length) {
    //     this.$refs.addMemberForm.clearValidate("region");
    //   }
    // }
  },
  mounted() {
    const end = new Date();
    const start = new Date();
    this.advancedForm.times = [start, end];
    this.searchEvent();
  },
  beforeRouteUpdate(to, from, next) {
    this.searchEvent();
    next();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 搜索事件触发 */
    searchEvent() {
      this.params.page_no = 1;
      delete this.params.begin_time;
      delete this.params.end_time;
      if (this.advancedForm.times) {
        // 这个接口是第三方接口 跟其他接口入参不一样的  直接传时间的   不是时间戳
        this.params.begin_time = this.generateTimeReqestNumber(
          this.advancedForm.times[0]
        );
        this.params.end_time = this.generateTimeReqestNumber(
          this.advancedForm.times[1]
        );
        // 凡是涉及到时间的前后台交互的都是秒级时间戳
        // this.params.begin_time = parseInt(this.advancedForm.times[0] / 1000)
        // this.params.end_time = parseInt(this.advancedForm.times[1] / 1000)
      }
      delete this.params.times;
      this.params.pay_type = this.advancedForm.pay_type;
      console.log(this.params);
      this.GET_List();
    },
    generateTimeReqestNumber(date1) {
      const date = new Date(date1);
      return (
        date.getFullYear().toString() +
        this.pad2(date.getMonth() + 1) +
        this.pad2(date.getDate()) +
        this.pad2(date.getHours()) +
        this.pad2(date.getMinutes()) +
        this.pad2(date.getSeconds())
      );
    },
    pad2(n) {
      return n < 10 ? "0" + n : n;
    },
    /** 获取对账列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Account.getPaymentCheck(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
div.toolbar-btns .conditions {
  margin-right: 0px;
}
</style>
