/**
 * 财务相关API
 */

import request from '@/utils/request'

/**
 * 查询提现记录表列表，用于分页查询
 * @param params
 */
export function getAccountList(params) {
  return request({
    url: '/admin/distribution/withdrawal/page',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询账户交易流水表列表，用于分页查询
 * @param params
 */
export function getTradeList(params) {
  return request({
    url: '/admin/distribution/trade/page',
    method: 'post',
    loading: false,
    params
  })
}

/** 获取结算明细 */
export function getBillData(params) {
  return request({
    url: 'admin/distribution/trade/bill',
    method: 'get',
    params
  })
}

/** 导出结算明细 */
export function exportBillData(params) {
  return request({
    url: 'admin/distribution/trade/exportBill',
    method: 'get',
    params
  })
}

/**
 * 退款明细导出
 */
export function exportRefundData(params) {
  return request({
    url: 'admin/shops/export',
    method: 'get',
    params
  })
}

/**
 * 查询账户交易流水详情表详情
 * @param params
 */
export function getTradeInfo(params) {
  return request({
    url: '/admin/distribution/trade/info',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询一个商户的退款明细
 * @param params
 */
export function getRefundDetail(params) {
  return request({
    url: '/admin/shops/getRefundDetail',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 每日营收
 * @param params
 */
export function getRevenueDay(params) {
  return request({
    url: '/admin/distribution/statistic/revenueDay',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 平台营收统计
 * @param params
 */
export function getRevenue(params) {
  return request({
    url: '/admin/distribution/statistic/revenue',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 对账
 * @param params
 */
export function getPaymentCheck(params) {
  return request({
    url: 'admin/order/bills/paymentCheck',
    method: 'get',
    loading: false,
    params
  })
}
